<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)" />
    <TopNavigation step="0" title="Document Preview" icon="searchengin" hideSecondNavbar="true" :desc="projName" />

    <div class="contentDivNoGap">
      <div class="row">
        <!-- <span class="toolboxBtn" @click="toggleToolbox">
          <fa :icon="toolboxBtnIcon" class="text-white" />
        </span> -->
        <span v-if="!showToolbox" class="toolboxBtnClose" @click="toggleToolbox">
          <fa icon="chevron-right" class="text-white" />
        </span>

        <span v-if="showToolbox" class="toolboxBtnOpen" @click="toggleToolbox">
          <fa icon="chevron-left" class="text-white" style="padding-left: 2px;" />
        </span>
        
        <!-- Toolbox -->
        <div v-if="showToolbox" class="bottom-0 p-0 vh-100 pe-1 toolbarViewFloat" style="z-index: 2">
            <div id="accordion" class="pt-1 ps-1">
                <div class="card mb-2">
                    <div class="card-header">
                      <div class="d-grid pt-1 px-2">
                          <div class="btn-group">
                            <button v-if="doc" type="button" @click="router.push('/uploadDoc/' + doc.folderId)" class="btn btn-secondary">
                                <fa icon="chevron-left" class="me-2" />Back
                            </button>
                            <button class="btn btn-secondary" @click="router.push('/project')">
                                <fa icon="folder" class="me-2" />Folder
                            </button>
                          </div>
                      </div>
                    </div>

                    <div id="left1" class="collapse show" >
                        <div class="card-body">
                            <div class="py-2">
                                <table border="0" align="center">
                                    <tr v-if="editPage === false">
                                        <td v-if="page === 1" class="pe-2 text-secondary"><fa icon="angle-double-left" :disabled="page === 1" /></td>
                                        <td v-if="page !== 1" class="pe-2 isLink" @click="navigatePage('first'); updPageNum(page)"><fa icon="angle-double-left" /></td>
                                        <td v-if="page <= 1" class="px-2 text-secondary"><fa icon="angle-left" /></td>
                                        <td v-if="page > 1" class="px-2 isLink" @click="navigatePage('prev'); updPageNum(page)"><fa icon="angle-left" /></td>
                                        <td><span class="isLinkPrimary" @click="editPage = true">{{ goToPage.toLocaleString() }}</span> / {{ pageCount.toLocaleString() }}</td>
                                        <td v-if="page >= pageCount" class="px-2 text-secondary"><fa icon="angle-right" /></td>
                                        <td v-if="page < pageCount" class="px-2 isLink" @click="navigatePage('next'); updPageNum(page)"><fa icon="angle-right" /></td>
                                        <td v-if="page === pageCount" class="ps-2 text-secondary"><fa icon="angle-double-right" /></td>
                                        <td v-if="page !== pageCount" class="ps-2 isLink" @click="navigatePage('last'); updPageNum(page)"><fa icon="angle-double-right" /></td>
                                    </tr>
                                    <tr v-else>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <span class="input-group input-group-xs" style="width: 150px">
                                                    <span class="input-group-text">Go to page</span>
                                                    <input type="text" v-model="goToPage" autofocus class="form-control form-control-xs" @input="navigatePage(); updPageNum(page)" @focusout="editPage = false" >
                                                </span>
                                                <span class="ps-2 pt-1">/ {{ pageCount.toLocaleString() }}</span>
                                            </div>
                                        </td>
                                </tr>
                                </table>
                            </div>

                            <div class="pb-2">
                                <table align="center" border="0">
                                    <tr> 
                                    <td  width="50px" class="px-2 text-end isLink" @click="zoomOut"><fa icon="minus"></fa></td>
                                    <td v-if="scale === 1"  width="70px" class="pe-2 text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                    <td v-else  width="70px" class="pe-2 isLink text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                    <td  width="50px" class="ps-2 isLink pe-1" @click="zoomIn"><fa icon="plus"></fa></td>
                                    </tr>
                                </table>
                            </div>
                            <!-- <div class="bg-danger text-white">browserWidth: {{ browserWidth }}</div>
                            <div class="bg-danger text-white">scale: {{ scale }} </div>
                            <div class="bg-danger text-white" v-if="doc">spageWidth: {{ doc.jFile.pageWidth }}</div> -->
                        </div>
                    </div>
                </div>

                <div v-if="doc" class="card mb-2">
                    <div class="card-header">
                        <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left2" style="width: 100%" @click="showBoxInfo = !showBoxInfo">Document Information
                          <span class="float-end" :hidden="showBoxInfo === false"><fa icon="chevron-up" /></span>
                          <span class="float-end" :hidden="showBoxInfo === true"><fa icon="chevron-down" /></span>
                        </a>
                    </div>

                    <div id="left2" class="collapse show" >
                        <div class="card-body pb-0 px-2">
                          
                            <!-- Method-2 -->
                            <table class="table table-borderless table-sm" border="0">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <span v-if="docStatus !== ''"><DocStatus :status="docStatus" :shred="doc.shred" class="ms-0 ps-0 me-2" /></span>
                                            <Popper class="popperDark" arrow hover :content="docName">
                                                <span>{{ (docName.length > 25 ? docName.substring(0, 25) + '...' : docName) }}</span>
                                            </Popper>
                                        </td>
                                    </tr>
                                    <tr v-if="arrRejectedBySignee && arrRejectedBySignee.length > 0">
                                      <td colSpan="2" class="text-end lblTiny">
                                        <div v-for="r in arrRejectedBySignee" :key="r">
                                          <span class="text-danger fw-bold">REJECTED</span> by 
                                          <Popper class="popperDark" arrow hover :content="r.signeeId">{{ r.signeeName }}</Popper>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr v-if="doc.dateModify">
                                      <td colSpan="2" class=" pb-2">
                                        <span v-if="doc.shred === 'STAGING'" class="lblTiny me-2">
                                            <Popper class="popperDark" arrow hover :content="'Flag to shred'">
                                                <fa icon="flag" size="lg" class="text-danger" />
                                            </Popper>
                                        </span>

                                        <span class="lblTiny float-end">
                                            <span class="lblTiny mt-0">Modified </span>
                                            <Popper class="popperDark" arrow hover :content="'Created ' + func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date())">
                                                <span v-if="doc.dateModify === '0'" class="isTooltipsLabel lblTiny mt-0 fst-italic">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date()) }}</span>
                                                <span v-else class="isTooltipsLabel lblTiny mt-0 fst-italic">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateModify), new Date()) }}</span>
                                            </Popper>
                                        </span>
                                            
                                      </td>
                                    </tr>
                                </tbody>
                            </table>

                            <!-- <div class="bg-danger text-white">isEncrypted: {{ isEncrypted }}</div> -->

                            <div v-if="isEncrypted === true || doc.jFile.totalOldSignature > 0" class="mt-2 p-2 mb-3 border shadow-sm" style="border-radius: 5px">
                                <div><fa icon="circle-info" size="lg" /> Information</div>
                                <div class="lblTiny">
                                  <!-- isEncrypted: {{ isEncrypted }} | totalOldSignature: {{ doc.jFile.totalOldSignature }} -->
                                  <span v-if="isEncrypted === true && doc.jFile.totalOldSignature > 0">
                                    This document is encrypted and has {{ doc.jFile.totalOldSignature }} existing signature{{ doc.jFile.totalOldSignature > 1 ? 's' : '' }} found before processing, before upload
                                  </span>
                                  <span v-else-if="isEncrypted === true">
                                    This document is encrypted
                                  </span>
                                  <span v-else-if="doc.jFile.totalOldSignature > 0">
                                    This document has {{ doc.jFile.totalOldSignature }} existing signature{{ doc.jFile.totalOldSignature > 1 ? 's' : '' }} found before processing, before upload
                                  </span>
                                
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div v-if="doc" class="card mb-2">
                    <div class="card-header">
                        <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left3" style="width: 100%" @click="showBoxSignee = !showBoxSignee">Signee(s)
                          <span class="float-end" :hidden="showBoxSignee === false"><fa icon="chevron-up" /></span>
                          <span class="float-end" :hidden="showBoxSignee === true"><fa icon="chevron-down" /></span>
                        </a>
                    </div>

                    <div id="left3" class="collapse show" >
                        <div class="card-body pb-0 px-2">
                          
                            <!-- Method-2 -->
                            <table class="table table-borderless table-sm" border="0">
                                <tbody>
                                    <tr v-if="docSignee && docSignee.length === 0"><td colSpan="2">N/A</td></tr>

                                    <tr v-for="s in docSignee.slice(0, 5)" :key="s">
                                        <td width="45px" class="align-middle">
                                            <ImgAvatar :isUser="s.isUser" :id="s.jAvatar.userId" displayName="" :isVerified="s.isVerified" :isActive="s.status" :privilege="s.privilege" width="35" height="35" />
                                            <!-- <Avatar :isUser="s.isUser" :avatar="s.jAvatar.imgBase64" :isVerified="s.isVerified" :privilege="s.privilege" displayName="" :isActive="s.status" width="35" height="35" class="ps-2"/> -->
                                        </td>
                                        <td>
                                            <div>{{s.signeeName}}</div>
                                            <div class="lblTiny">{{ s.signeeId }}</div>
                                            <div class="lblTiny">
                                                <span v-if="s.signingStatus === 'PENDINGSIGN'" class="fw-bold">PENDING</span>
                                                <span v-else-if="s.signingStatus === 'REJECTED'" class="fw-bold" :class="s.signingStatus === 'REJECTED' ? 'text-danger' : ''">
                                                  {{s.signingStatus}}
                                                  <span v-if="doc.isEditor === true || doc.jUSignee.isDocumentSignee === true" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlDelReject">
                                                      <Popper class="popperDark" arrow hover content="Undo sign reject">
                                                          <fa icon="trash" class="text-primary ms-2" />
                                                      </Popper>
                                                  </span>
                                                </span>
                                                <span v-else class="fw-bold" :class="s.signingStatus === 'REJECTED' ? 'text-danger' : ''">{{s.signingStatus}} </span>
                                                <span v-if="s.privilege !== 'SIGNEE'" class="float-end">{{s.privilege}}</span>
                                            </div>
                                            <div v-if="s.comment" class="mt-1 mb-0 border p-2 lblTiny" style="border-radius: 10px;">{{s.comment}}</div>
                                            <div v-if="s.jRemoval" class="mb-0 pt-1 px-2 lblTiny text-end" style="border-radius: 10px;">
                                                <span v-if="s.jRemoval.status === 'APPROVED'" class="text-warning text-start">
                                                    <Popper class="popperDark" arrow hover :content="'A request to shred document is approved at ' + func.convDateTimeFormat(s.jRemoval.dateAction, 'datetime')">Shred approved</Popper>
                                                </span>
                                                <span v-if="s.jRemoval.status === 'REJECTED'" class="text-warning text-start">
                                                    <span v-if="s.signeeId === getUserId" class="me-2 isLink" data-bs-toggle="modal" :data-bs-target="'#mdlShredCancelReject'">
                                                        <Popper class="popperDark" arrow hover content="Undo shred reject" >
                                                            <fa icon="trash" />
                                                        </Popper>
                                                    </span>
                                                    <Popper class="popperDark" arrow hover :content="'A request to shred document is rejected at ' + func.convDateTimeFormat(s.jRemoval.dateAction, 'datetime')">Shred rejected</Popper>
                                                </span>
                                                <span v-if="s.jRemoval.status === 'PENDING'" class="text-warning text-start">
                                                    <Popper class="popperDark" arrow hover :content="'A request to shred document is pending for signee\'s approval'">Shred pending</Popper>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-for="s1 in docSignee.slice(5)" :key="s1">
                                        <td width="45px" class="align-middle">
                                            <ImgAvatar :isUser="s1.isUser" :id="s1.jAvatar.userId" displayName="" :isVerified="s1.isVerified" :isActive="s1.status" privilege="" width="35" height="35" />
                                            <!-- <Avatar :isUser="s1.isUser" :avatar="s1.jAvatar.imgBase64" :isVerified="s1.isVerified" :privilege="s1.privilege" displayName="" :isActive="s1.status" width="35" height="35" class="ps-2"/> -->
                                        </td>
                                        <td>
                                            <div>{{s1.signeeName}}</div>
                                            <div class="lblTiny">{{ s1.signeeId }}</div>
                                            <div class="lblTiny">
                                                <span v-if="s1.signingStatus === 'PENDINGSIGN'">PENDING</span>
                                                <span v-else-if="s1.signingStatus === 'REJECTED'" class="fw-bold" :class="s1.signingStatus === 'REJECTED' ? 'text-danger' : ''">
                                                  {{s.signingStatus}}
                                                  <span v-if="doc.isEditor === true || doc.jUSignee.isDocumentSignee === true" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlDelReject">
                                                      <Popper class="popperDark" arrow hover content="Undo sign reject">
                                                          <fa icon="trash" class="text-primary ms-2" />
                                                      </Popper>
                                                  </span>
                                                </span>
                                                <span v-else class="fw-bold" :class="s1.signingStatus === 'REJECTED' ? 'text-danger' : ''">{{s1.signingStatus}} </span>
                                                <span v-if="s1.privilege !== 'SIGNEE'" class="float-end">{{s1.privilege}}</span>
                                            </div>
                                            <div v-if="s1.comment" class="mt-1 mb-0 border p-2 lblTiny" style="border-radius: 10px;">{{s1.comment}}</div>
                                            <div v-if="s1.jRemoval" class="mb-0 pt-1 px-2 lblTiny text-end" style="border-radius: 10px;">
                                                <span v-if="s1.jRemoval.status === 'APPROVED'" class="text-warning text-start">
                                                    <Popper class="popperDark" arrow hover :content="'A request to shred document is approved at ' + func.convDateTimeFormat(s1.jRemoval.dateAction, 'datetime')">Shred approved</Popper>
                                                </span>
                                                <span v-if="s1.jRemoval.status === 'REJECTED'" class="text-warning text-start">
                                                    <span v-if="s1.signeeId === getUserId" class="me-2 isLink" data-bs-toggle="modal" :data-bs-target="'#mdlShredCancelReject'">
                                                        <Popper class="popperDark" arrow hover content="Undo shred reject" >
                                                            <fa icon="trash" />
                                                        </Popper>
                                                    </span>
                                                    <Popper class="popperDark" arrow hover :content="'A request to shred document is rejected at ' + func.convDateTimeFormat(s1.jRemoval.dateAction, 'datetime')">Shred rejected</Popper>
                                                </span>
                                                <span v-if="s1.jRemoval.status === 'PENDING'" class="text-warning text-start">
                                                    <Popper class="popperDark" arrow hover :content="'A request to shred document is pending for signee\'s approval'">Shred pending</Popper>
                                                </span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="docSignee.length > 5 && !showMoreSignee">
                                            <td colSpan="2" class="text-end lblTiny" @click="showMoreSignee = !showMoreSignee">+ {{docSignee.length - 5}} more</td>
                                        </tr>
                                        <tr v-if="docSignee.length > 5 && showMoreSignee">
                                            <td colSpan="2" class="text-end lblTiny" @click="showMoreSignee = !showMoreSignee">- {{docSignee.length - 5}} less</td>
                                        </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <div v-if="doc" class="card mb-2">
                  <div class="card-header">
                      <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left4" style="width: 100%" @click="showBoxActivity = !showBoxActivity">Activities
                          <span class="float-end" :hidden="showBoxActivity === false"><fa icon="chevron-up" /></span>
                          <span class="float-end" :hidden="showBoxActivity === true"><fa icon="chevron-down" /></span>
                      </a>
                  </div>

                  <div id="left4" class="collapse show" >
                      <div class="card-body pb-0 px-2">
                          <table class="table table-borderless table-sm">
                              <tbody>
                                  <tr>
                                      <td><span class="isLink" data-bs-toggle="modal" data-bs-target="#mdlActivity">View Activities</span></td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                  </div> 
                </div>

                <div v-if="doc && doc.jFile && (doc.privilege === 'OWNER' || doc.privilege === 'EDITOR' || doc.privilege === 'SIGNEE' || doc.privilege === 'VIEWER')" class="card mb-2">
                        <div class="card-header">
                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left5" style="width: 100%" @click="showBoxDownload = !showBoxDownload">Download
                                <span class="float-end" :hidden="showBoxDownload === false"><fa icon="chevron-up" /></span>
                                <span class="float-end" :hidden="showBoxDownload === true"><fa icon="chevron-down" /></span>
                            </a>
                        </div>

                        <div id="left5" class="collapse show" >
                            <div class="card-body pb-0 px-2">
                                <table class="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="mb-2">
                                                    <div class="isLink" @click="downloadDoc"><fa icon="file-signature" class="me-1" />Original Signed Version</div>
                                                </div>
                                                <div v-if="doc.jFile.dlAuditRptUrl !== undefined && doc.status !== 'VIEWONLY'">
                                                    <div class="isLink" @click="downloadAuditLog"><fa icon="file-alt" class="me-2" />Audit Report</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

            </div> 

        </div><!-- End of Toolbox -->
        
        <!-- <div class="col-12 px-0 pt-0 bg-info" style="padding-bottom: 100px"> -->
        <div>
          <div class="vh-100 gx-0" style="padding-bottom: 40px">

            <!-- <div v-if="isPdfLoaded === false && doc && isEncrypted === false" class="text-center text-info" style="width: 100%; position: fixed; z-index: 1000; top: 100px; font-size: 14px">
                <fa icon="spinner" size="lg" class="me-2" />Loading...
            </div> -->

            <div v-if="isPdfLoaded === false && doc" class="text-center vh-100" style="width: 100%; position: fixed; z-index: 1000; top: 42px; background-color: rgba(0, 0, 0, 0.3); font-size: 28px;">
                <div class="position-absolute top-50 start-50 translate-middle text-info">
                    <i class="fa-solid fa-spinner faa-spin animated"></i> Loading...
                </div>
            </div>
            
            <!-- <div style="position: relative; width: auto; height: 100%; margin-top: 0px; overflow: auto; top: 0px; border: 2px solid red" class="pdfBgView"> -->
            <div style="position: relative; width: auto; height: 100%; overflow: auto; top: 0px; padding-top: 0px" class="pdfBgView">
                <div v-if="doc" id="pdfViewBoxContainer" ref="pdfSignBoxContainer" :style="'width:'+Math.ceil(pdfWidth*scale)+'px; height: '+Math.ceil(pdfHeight*scale)+'px; position: absolute; right: 0px; border: 0px dashed orange'">
                </div>
                <canvas v-if="doc" id="pdf-view-canvas" style="position: absolute; right: 0px; border: 0px dashed green"></canvas>
                <div v-if="doc" id="pdf-view-annotation-layer" style="position: absolute; right: 0px; border: 0px dashed blue"></div>

                <!-- yntmp: hide
                <div v-if="doc && doc.jFile.isEncrypted === true" class="row mt-4">
                    <div class="col-5 col-lg-4 mb-2 text-end">
                        <fa icon="triangle-exclamation" style="font-size: 120px"/>
                    </div>
                    <div class="col-7 col-lg-8 mb-2">
                        <div class="fw-bold mb-3" style="font-size: 20px">Encrypted document</div>
                        <div>Sorry, encrypted PDF is not supported at the moment.</div>
                    </div>
                </div> -->
            </div>
            
          </div>
        </div>
      </div>
    </div>  

    <!-- Modal: Confirm to remove reject status --> 
    <div class="modal fade" id="mdlDelReject">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to undo sign reject?
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                  <button class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelSignReject">
                        <fa icon="trash" size="lg" class="me-2" />Undo
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: mdlActivity --> 
    <div
        class="modal fade"
        id="mdlActivity"
        tabindex="-1"
        aria-labelledby="mdlActivity"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">View Activites</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div id="auditLogBox" v-if="doc">
                        <table class="table table-sm table-hover">
                            <tbody>
                                <tr v-for="audit in doc.jAuditLog" :key="audit">
                                    <td>
                                        <div class="row">
                                            <div class="col-8 mb-2">
                                                <div>{{audit.userId}}</div>
                                                <div class="small">{{ func.convDateTimeFormat(audit.date, 'text')}}</div>
                                            </div>
                                            <div class="col-4 mb-2 text-end">
                                                <div>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'windows'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'windows']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'linux'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'linux']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'ubuntu'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'ubuntu']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'redhat'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'redhat']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'fedora'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'fedora']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'centos'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'centos']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jOperatingSystem.name.toLowerCase() === 'mac os'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jOperatingSystem.name + ' ' + audit.jOperatingSystem.version">
                                                            <fa :icon="['fab', 'apple']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                            
                                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'chrome'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                                            <fa :icon="['fab', 'chrome']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'firefox'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                                            <fa :icon="['fab', 'firefox']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'edge'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                                            <fa :icon="['fab', 'edge']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>
                                                    <span v-if="audit.jBrowser.name.toLowerCase() === 'mobile safari'">
                                                        <Popper class="popperDark" arrow hover :content="audit.jBrowser.name + ' ' + audit.jBrowser.version">
                                                            <fa :icon="['fab', 'safari']" style="font-size: 25px" class="me-2" />
                                                        </Popper>
                                                    </span>

                                                    <span>
                                                        <Popper class="popperDark" arrow hover :content="audit.jGeoLocation.city + ', ' + audit.jGeoLocation.state + ', ' + audit.jGeoLocation.countryName">
                                                            <fa icon="location-dot" style="font-size: 25px" />
                                                        </Popper>
                                                    </span>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 mb-3">{{audit.description}}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> 
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="times" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, inject, onMounted, watch, onBeforeUnmount } from 'vue'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import DocStatus from '@/components/DocStatus.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import UserDisplayName from '@/components/UserDisplayName.vue'

let pdfjsDoc = null // for pdfjslib

export default {
    name: 'PdfView',
    components: { Alert, TopNavigation, degrees, PDFDocument, rgb, StandardFonts, DocStatus, Popper, /* Avatar,  */ImgAvatar, UserDisplayName },
    setup () {
        const url = ref(null)
        const urlEncode = ref(null)
        
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const { getTotalPage, getUserId } = useStore()
        const func = funcs
        const alert = ref([])

        const isSignOn = ref(false) // detect if from signon Entity

        const pdfWidth = ref(0)
        const pdfHeight = ref(0)
        const uiRatio = ref(0) // ratio between UI width and PDF actual width. 

        const editPage = ref(false)
        const page = ref(1)
        const pageCount = ref(1)
        const goToPage = ref(1)
        const randKey = ref(1)
        const isPdfLoaded = ref(false)
        const scale = ref(1)
        const isEncrypted = ref(false)  // if document is encrypted
      
        const doc = ref(null)
        const docName = ref(null)
        const docStatus = ref(null)
        const docSignee = ref([])
        const projName = ref(null)
        const projPrivilege = ref(null)
        const showToolbox = ref(true)
        const showMoreSignee = ref(false)
        const toolboxBtnIcon = ref('chevron-left')
        const curPage = ref(1) // update tally to page

        const browserWidth = ref(0) // browser width
        const docWidth = ref(0) //pt
        const docHeight = ref(0) //pt

        const showBoxInfo = ref(true)
        const showBoxSignee = ref(true)
        const showBoxActivity = ref(true)
        const showBoxDownload = ref(true)

        const arrRejectedBySignee = ref([])

        /* 
        watch([url], async ([newUrl, oldUrl]) => {
            console.info('WATCH', 'url', oldUrl, newUrl)
            clearAnnotation()
            readDoc(url.value)

        })

        watch([page, scale], async ([newPage, newScale], [oldPage, oldScale]) => {

            console.info('WATCH' ,'page / scale', '|', 'Page:', newPage === oldPage ? '-' : 'changed', 'Scale:', newScale === oldScale ? '-' : 'changed')
            console.info(' - - - WATCH' ,'Page:', oldPage, newPage, '|', 'Scale:', oldScale, newScale)
            clearAnnotation()
            readPage(page.value, scale.value, 'watch')
        })
        */

        watch([docWidth], async () => {

            const oldCanvas = document.getElementById('pdf-view-canvas')
            if (oldCanvas) {
                const oldCtx = oldCanvas.getContext('2d')
                oldCtx.clearRect(0, 0, oldCanvas.width, oldCanvas.height)
                oldCtx.beginPath()
                oldCtx.closePath()
                oldCtx.restore()
            }

            setTimeout(() => {
                iniScale()
            }, 400)
            setTimeout(() => {
                randKey.value = Math.floor(Math.random() * 1000)
            }, 500)

        })

        // watch([page, scale, docWidth], async ([newPage, newScale, newDocWidth], [oldPage, oldScale, oldDocWidth]) => {
        watch([page, scale], async ([newPage, newScale], [oldPage, oldScale]) => {
            // console.info('* * WATCH pg' + page.value ,'page / scale')
            // console.info('WATCH' ,'page / scale', '|', 'Page:', newPage === oldPage ? '-' : 'changed', '|', 'Scale:', newScale === oldScale ? '-' : 'changed')
            clearAnnotation()

            if (pdfjsDoc === null) {
                readDoc(url.value)
            } else {
                readPage(page.value, scale.value, 'watch')
            }
        })

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const readDoc = async (url) => {
            // console.info('- - readDoc', 'isEncrypted', doc.value.jFile.isEncrypted)
            isPdfLoaded.value = false
            // console.info('- - readDoc')
            if (doc.value.jFile.isEncrypted === true || doc.value.jFile.isEncrypted === 'true') {
                // Read encrypted PDF
                /* WORKING: pass in new URL method, can open encrypted pdf (slower) */
                const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
                const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
                if (window.navigator['msSaveOrOpenBlob']) {
                    // console.info('blob')
                    window.navigator['msSaveBlob'](blob, filename)
                }
                else {
                    const newurl =  window.URL.createObjectURL(blob)
                    // const newurl =  'http://localhost:8080/maxis.pdf'
                    // const newurl =  'http://localhost:8080/agreement.pdf'
                    // console.info('newurl', newurl)

                    const loadingTask = pdfjsLib.getDocument(newurl)
                    // const loadingTask = pdfjsLib.getDocument({ url: pdfVal, password: ''})
                    loadingTask.promise.then((doc) => {
                        pdfjsDoc = doc
                        // console.info('totalpage', doc.numPages)
                        pageCount.value = doc.numPages
                        readPage(page.value, scale.value, 'readDoc')

                    },(error) => {
                        isPdfLoaded.value = true

                        if (error.name === 'PasswordException') {
                            console.info('The PDF is password-protected and no password was provided.')
                        } else {
                            console.info('ERROR loading the PDF:', error)
                        }
                    })
                }

            } else {
                // Read not encryped PDF
                /* pdfjs - working, but cannot open encrypted pdf (faster) */
                const existingPdfBytes = await fetch(url, { credentials: 'include' }).then(res => res.arrayBuffer())
                const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true })
                // const pdfDoc = await PDFDocument.load(existingPdfBytes)
                const pdfVal =  await pdfDoc.saveAsBase64({ dataUri: true })

                const loadingTask = pdfjsLib.getDocument(pdfVal)
                // const loadingTask = pdfjsLib.getDocument({ url: pdfVal, password: ''})
                loadingTask.promise.then((doc) => {
                    pdfjsDoc = doc
                    // console.info('total page', doc.numPages)
                    pageCount.value = doc.numPages
                    readPage(page.value, scale.value, 'readDoc')

                },(error) => {
                    isPdfLoaded.value = true

                    if (error.name === 'PasswordException') {
                        console.info('The PDF is password-protected and no password was provided.')
                    } else {
                        console.info('ERROR loading the PDF:', error)
                    }
                })
                
            }

            
            /* ORIG 
            const loadingTask = pdfjsLib.getDocument(pdfVal)
            // const loadingTask = pdfjsLib.getDocument({url: pdfVal, password: xx})
            loadingTask.promise.then((doc, reason) => {
                pdfjsDoc = doc
                
                pageCount.value = doc.numPages
                readPage(page.value, scale.value)
                isPdfLoaded.value = true
            }) */

            /* 
            urlEncode.value = Pdfvuer.createLoadingTask(newurl)
            urlEncode.value.then(pdf => {
                pageCount.value = pdf.numPages
                isPdfLoaded.value = true
            }) */
        }

        const readPage = async (pageNum, pdfScale, fr) => {
            
            const oldCanvas = document.getElementById('pdf-view-canvas')
            if (oldCanvas) {
                const oldCtx = oldCanvas.getContext('2d')
                oldCtx.clearRect(0, 0, oldCanvas.width, oldCanvas.height)
                oldCtx.beginPath()
                oldCtx.closePath()
                oldCtx.restore()
            }

            // then method
            if(pdfjsDoc) 
            {
                // console.info('*** readPage ['+fr+']', pageNum, pdfScale, doc.value.jFile.isEncrypted)
                // await clearCanvas()
                // console.info('- - - - - readPage')

                pdfjsDoc.getPage(pageNum).then((page) => {
                    const viewport = page.getViewport({ scale: pdfScale * 1.33333 })
                    const viewportOrigScale = page.getViewport({ scale: 1 })

                    docWidth.value = Math.floor(viewportOrigScale.width)
                    docHeight.value = Math.floor(viewportOrigScale.height)

                    // console.info('viewportOrigScale pg'+pageNum, Math.floor(viewportOrigScale.width), 'x', Math.floor(viewportOrigScale.height), 'pt')

                    // console.info('page: '+pageNum, viewportOrigScale.width, 'x', viewportOrigScale.height, 'x Scaled (pt)')

                    const containerDiv = document.getElementById('pdfViewBoxContainer')
                    containerDiv.style.width = viewport.width + 'px'
                    containerDiv.style.height = viewport.height + 'px'
                    containerDiv.styleborder = '0px solid orange'

                    // console.info('canvas viewport.width:', viewport.width)

                    const canvas = document.getElementById('pdf-view-canvas')
                    const context = canvas.getContext('2d')
                    // context.clearRect(0, 0, Math.floor(viewport.width) * resolution, Math.floor(viewport.height) * resolution)
                    const resolution = window.devicePixelRatio || 1

                    // console.info('canvas: pdfBoxContainer', canvas)
                    canvas.width = Math.floor(viewport.width) * resolution
                    canvas.height = Math.floor(viewport.height) * resolution
                    canvas.style.width = Math.floor(viewport.width) + 'px'
                    canvas.style.height = Math.floor(viewport.height) + 'px'
                    canvas.style.border = '0px solid blue'

                    context.scale(resolution, resolution)

                    const transform = [1, 0, 0, 1, 0, 0]

                    // Render PDF page into canvas context
                    const renderContext = {
                        canvasContext: context,
                        transform,
                        viewport,
                    }
                    page.render(renderContext)
                    // const opList = await page.getOperatorList()

                    const element = document.getElementById('pdf-view-annotation-layer')
                    element.width = Math.floor(viewport.width) * resolution
                    element.height = Math.floor(viewport.height) * resolution
                    element.style.width = Math.floor(viewport.width) + 'px'
                    element.style.height = Math.floor(viewport.height) + 'px'
                    // element.style.border = '2px solid red'
                    
                    /* const annots = await page.getAnnotations()
                    for (const annot of annots) {
                        if(annot && annot.subtype === 'Link') {
                            renderAnnotationLink(annot, viewport, resolution, element)
                        } 
                    } */

                    page.getAnnotations().then((annots) => {
                        for (const annot of annots) {
                            if(annot && annot.subtype === 'Link') {
                                renderAnnotationLink(annot, viewport, resolution, element)
                            } 
                        }
                    }, (err) => {
                        console.info('readPage annotation error', err)
                    })

                    isPdfLoaded.value = true

                },(error) => {
                    console.info('readPage error', error)
                })
            }
        }

        const readPage_orig = async (pageNum, pdfScale) => {

            if(pdfjsDoc) 
            {
                const page = await pdfjsDoc.getPage(pageNum)
                // console.info('page', page)
                const viewport = page.getViewport({ scale: pdfScale * 1.33333 })

                const containerDiv = document.getElementById('pdfViewBoxContainer')
                containerDiv.style.width = viewport.width + 'px'
                containerDiv.style.height = viewport.height + 'px'
                containerDiv.styleborder = '0px solid orange'

                // console.info('canvas viewport.width:', viewport.width)

                const canvas = document.getElementById('pdf-view-canvas')
                const context = canvas.getContext('2d')
                context.clearRect(0, 0, Math.floor(viewport.width) * resolution, Math.floor(viewport.height) * resolution)
                const resolution = window.devicePixelRatio || 1

                // console.info('canvas: pdfBoxContainer', canvas)
                canvas.width = Math.floor(viewport.width) * resolution
                canvas.height = Math.floor(viewport.height) * resolution
                canvas.style.width = Math.floor(viewport.width) + 'px'
                canvas.style.height = Math.floor(viewport.height) + 'px'
                canvas.style.border = '0px solid blue'

                context.scale(resolution, resolution)

                const transform = [1, 0, 0, 1, 0, 0]

                // Render PDF page into canvas context
                const renderContext = {
                    canvasContext: context,
                    transform,
                    viewport,
                }
                page.render(renderContext)
                // const opList = await page.getOperatorList()

                const element = document.getElementById('pdf-view-annotation-layer')
                element.width = Math.floor(viewport.width) * resolution
                element.height = Math.floor(viewport.height) * resolution
                element.style.width = Math.floor(viewport.width) + 'px'
                element.style.height = Math.floor(viewport.height) + 'px'
                // element.style.border = '2px solid red'
                
                const annots = await page.getAnnotations()
                for (const annot of annots) {
                    if(annot && annot.subtype === 'Link') {
                        renderAnnotationLink(annot, viewport, resolution, element)
                    } 
                }

                isPdfLoaded.value = true
            }
        }

        const renderAnnotationLink = (annotation, viewport, resolution, elAnnoLayer) => {
            const scale = viewport.scale

            const [x0, y0, x1, y1] = annotation.rect
            const yDiff = (y1 - y0) * scale
            const x = x0 * scale
            const y = Math.floor(viewport.height) - ( y0 * scale) - yDiff
            const width = (x1 - x0) * scale
            const height = (y1 - y0) * scale

            var lnk = window.document.createElement('a')
            lnk.href = annotation.url
            lnk.style.position = 'absolute'
            lnk.target = '_blank'
            lnk.style.left = `${x}px`
            lnk.style.top = `${y}px`
            lnk.style.width = `${width}px`
            lnk.style.height = `${height}px`
            // lnk.style.border = '1px solid red'
            // lnk.style.backgroundColor = 'rgba(255, 255, 0, 0.5)'
            // lnk.style.pointerEvents = 'none'
            // lnk.style.innerText = annotation.title

            elAnnoLayer.appendChild(lnk)
        }

        const clearAnnotation = async () => {
            const container = document.getElementById('pdf-view-canvas')
            if (container !== null) {
            while (container.firstChild) {
                container.removeChild(container.firstChild)
            }
            }

            const container2 = document.getElementById('pdf-view-annotation-layer')
            if (container2 !== null) {
            while (container2.firstChild) {
                container2.removeChild(container2.firstChild)
            }
            }
        }

        const clearCanvas = async () => {
            
            const canvas = document.getElementById('pdf-view-canvas')
            if (canvas) {
                const context = canvas.getContext('2d')
                context.clearRect(0, 0, canvas.width, canvas.height)
                context.beginPath()
                context.closePath()
                context.restore()
            }
        }

        const zoomIn = () => {
            // console.info('zoomIn +')
            scale.value = parseFloat((scale.value + 0.1).toFixed(1))
            randKey.value = Math.floor(Math.random() * 1000)

        }

        const zoomOut = async () => {
            // console.info('zoomOut -')
            scale.value = parseFloat((scale.value - 0.1).toFixed(1))
            randKey.value = Math.floor(Math.random() * 1000)
        }

        const zoomDefault = async () => {
            // console.info('zoomDef')
            scale.value = 1
            randKey.value = Math.floor(Math.random() * 1000)
        }

        const navigatePage = (opt) => {
            if (opt === 'prev') {
                page.value = page.value - 1
                goToPage.value = page.value

            } else if (opt === 'next') {
                page.value++
                goToPage.value = page.value

            } else if (opt === 'first') {
                page.value = 1
                goToPage.value = page.value

            } else if (opt === 'last') {
                page.value = pageCount.value
                goToPage.value = page.value     

            } else if (opt !== '' && opt > 0) {
                page.value = opt * 1
                goToPage.value = opt * 1  

            } else {
                goToPage.value = goToPage.value * 1
                if (goToPage.value > 0 && goToPage.value <= pageCount.value) {
                    page.value = goToPage.value

                } else { 
                    page.value = 1
                    goToPage.value = page.value
                }
            }
            randKey.value = Math.floor(Math.random() * 1000)
        }

        const updPageNum = (page1) => {
            curPage.value = page1
        }

        const toggleToolbox = async () => {
            localStorage.setItem('hasClickViewToolbox', true)
            showToolbox.value = !showToolbox.value
            localStorage.setItem('leftViewDrawer', showToolbox.value)
            // location.reload() // Need to detect window size

        }

        const downloadDoc = async () => {
            const existingPdfBytes = await fetch(doc.value.jFile.dlUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = doc.value.jFile.name

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const downloadAuditLog = async () => {
            const existingPdfBytes = await fetch(doc.value.jFile.dlAuditRptUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = doc.value.jFile.name.substring(0, doc.value.jFile.name.length-4) + '_AuditLog.pdf'

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
        }

        const cancelSignReject = () => {
            axios.put( '/signon/signee/' + route.params.projectId + '/' + route.params.documentId + '/CANCELREJECTED', {})
                .then((res) => {

                    if (res.data.status === 1001) {
                        router.push({ path: '/auth/login', query: {e: 'ns'} })

                    } else if (res.data.status === 1) {
                        iniLoad()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is cancelled for reject sign."
                        })

                    } else {
                        func.addLog('pdfView', 'cancelSignReject', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                })
                .catch((error) => {
                    func.addLog('pdfView', 'cancelSignReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const getDocument = async (id) => {
            const p = {
                orderBy: [
                    {
                        field: 'jAuditLog',
                        order: 'desc'
                    }
                ]
            }
            axios.post( '/signon/' + route.params.projectId + '/' + id, p)
            .then((res) => {
            
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                    // console.info('doc', '/signon/' + route.params.projectId + '/' + id)
                    // console.info('doc', JSON.stringify(res.data.data.jFile))
                    doc.value = res.data.data
                    url.value = res.data.data.jFile.viewUrl
                    docName.value = res.data.data.jFile.name.replace('.pdf', '')
                    docStatus.value = res.data.data.status
                    isEncrypted.value = res.data.data.jFile.isEncrypted
                    
                    if (res.data.data.jFile.isEncrypted === true) {
                        // console.info('getDocument: Encrypted')
                        // hardcoded encryption pdf to A4 (pt) due to backend cannot detect
                        docWidth.value = 596
                        docHeight.value = 842
                    } else {
                        
                        docWidth.value = res.data.data.jFile.pageWidth
                        docHeight.value = res.data.data.jFile.pageHeight

                        // console.info('getDocument: X Encrypted', res.data.data.jFile.pageWidth, res.data.data.jFile.pageHeight, 'pt')
                    }

                    /* if (res.data.data.jFile.pageWidth > 0) {
                        docWidth.value = res.data.data.jFile.pageWidth
                    }
                    if (res.data.data.jFile.pageHeight > 0) {
                        docHeight.value = res.data.data.jFile.pageHeight
                    } */
                    
                    if (doc.value.jSignee) {
                        // Get OWNER first
                        doc.value.jSignee.forEach((item, idx) => {
                        // console.info('item', JSON.stringify(item))
                        // console.info('item', item.signeeName, item.signingStatus, item.signeeId)
                            if (item.privilege === 'OWNER' ) {
                                docSignee.value.push(item)
                            }

                            if(item.signingStatus === 'REJECTED') {
                            arrRejectedBySignee.value.push({ signeeName: item.signeeName, signingStatus: item.signingStatus, signeeId: item.signeeId })
                            }

                        })

                        // Get EDITOR first
                        doc.value.jSignee.forEach((item, idx) => {
                            if (item.privilege === 'EDITOR' ) {
                                docSignee.value.push(item)
                            }

                        })
                    }

                    if (doc.value.jSignee) {
                    // Get non-creator
                    doc.value.jSignee.forEach((item, idx) => {
                        if (item.privilege !== 'OWNER' && item.privilege !== 'EDITOR' ) {
                            docSignee.value.push(item)
                        }
                    })
                    }

                } else {
                    func.addLog('pdfView', 'getDocument', res)

                    if (res && res.data !== null && res.data !== undefined) {
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })
                    }
                }

            })
            .catch((error) => {
                func.addLog('pdfView', 'getDocument  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
            })
        }

        const getProject = () => {
            return axios.post( '/signon/' + route.params.projectId, {}).then((res) => {
                if (res.data.status === 1001) {
                    router.push({ path: '/auth/login', query: {e: 'ns'} })

                } else if (res.data.status === 1) {
                projName.value = res.data.data.folderName
                projPrivilege.value = res.data.data.privilege

            } else {
                func.addLog('pdfView', 'getProject', res)

                if (res && res.data !== null && res.data !== undefined) {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.getError(res.data)
                    })
                }

            }

            })
            .catch((error) => {
                func.addLog('pdfView', 'getProject  - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
            })

        }

        const iniScale = async () => {
            // console.info('iniScale', scale.value, 'doc: ', docWidth.value, 'browser: ', browserWidth.value)
            // console.info('iniScale', 'browserWidth:', browserWidth.value, docWidth.value)

            if (browserWidth.value <= 380) {
                // cater for mobile phone
                scale.value = 0.4

            } else if (browserWidth.value <= 428) {
                // cater for mobile phone
                scale.value = 0.5

            } else if (browserWidth.value <= 820) {
                // cater for tablet
                scale.value = 0.7

            } else {
                if (docWidth.value) {
                    let pdfWidth = docWidth.value * 1.33333.toFixed(5) * 1 //convert docWidth from pt to px
                    // let pdfHeight = docHeight.value * 1.33333.toFixed(5) * 1 //convert docHeight from pt to px
                    let pdfBox = browserWidth.value - 255 // deduct toolbar

                    let newScale = scale.value
                    let newPdfWidth = pdfWidth

                    for(var tmpScale = 0.8; tmpScale <= 2; tmpScale+=0.1) {
                        tmpScale = tmpScale.toFixed(1) * 1
                        newPdfWidth = pdfWidth * tmpScale

                        if (newPdfWidth <= pdfBox) {
                            newScale = tmpScale
                        }
                    }
                    scale.value = newScale

                } 
            }

        }

        onMounted(() => {
            pdfjsDoc = null
            browserWidth.value = window.innerWidth
            // clearCanvas()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
            alert.value.push({
                class: "success",
                title: "SUCCESS",
                message: "Welcome to SignOn."
            })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
            if (route.query.l) { 
                alert.value.push({
                class: "success",
                title: "SUCCESS",
                message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                })
            }
            }

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            if (localStorage.getItem('leftViewDrawer') === 'true') {
            showToolbox.value = true

            } else if (localStorage.getItem('leftViewDrawer') === 'false') { 
                showToolbox.value = false

            } else {
            showToolbox.value = true
            localStorage.setItem('leftViewDrawer', true)
            }

            getDocument(route.params.documentId)
            getProject()

            setTimeout(() => {
                iniScale()
            }, 600)

        })

        return { 
            alert, closeAlert, store, route, router, func, url, urlEncode, showToolbox, toolboxBtnIcon, curPage, getTotalPage, isSignOn, 
            doc, docName, docStatus, updPageNum, isEncrypted, toggleToolbox, getDocument, 
            projName, projPrivilege, showMoreSignee, docSignee, 
            uiRatio, pdfWidth, pdfHeight, getUserId, zoomDefault, zoomIn, zoomOut, navigatePage, isPdfLoaded, pageCount, scale, page, pageCount, goToPage, randKey, editPage,
            browserWidth, docWidth, docHeight, arrRejectedBySignee, cancelSignReject, showBoxInfo, showBoxSignee, showBoxActivity, showBoxDownload, downloadDoc, downloadAuditLog
        }
    },
   
}
</script>
/* PENDING:
- select doc affect remark project name
- show selected color by default
*/

// NOTE: fontFamily,fontSize pass from parent to DragBox component thr props, other from component to parent thr getPosition
// npm i vue3-draggable-resizable
// https://www.npmjs.com/package/vue3-draggable-resizable
// npm i vue-pdf-embed
// https://www.npmjs.com/package/vue-pdf-embed
// npm i pdf-lib
// https://www.npmjs.com/package/pdf-lib
// npm i downloadjs

// test https://www.npmjs.com/package/vue3-date-time-picker
// https://vue3datepicker.com/installation/#global
// npm i vue3-date-time-picker

// References
// https://www.designcise.com/web/tutorial/how-to-apply-css-opacity-to-background-color-only
// scan signature https://www.youtube.com/watch?v=0ArXBk6vS5U

<style>
body {
  color: #6E777E;
  font-size: 14px;
  overflow: hidden;
}

.pdfBgView {
  background-image: url("../../assets/bgTrans1.png");
  width: 100%;
  height: 100%;
}

.parent {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px; 
  user-select: none;
}

.box {
  border-style: dashed;
  border-width: 3px;
  cursor: pointer;
  border-radius: 5px;
}

.box:hover {
  border-style: dashed;
  border-width: 3px;
  cursor: move;
}

.box:active {
  cursor: move;
  /* border: 2px dashed #e98f07; */
  border-style: dashed;
  border-width: 3px;
}

.boxSelected {
  border-width: 4px;
  border-style: solid;
}

.boxCal {
  padding-left: 8px;
  padding-right: 8px;
  width: 200px;
}

.toolboxBtnClose {
  position: fixed; 
  margin-top: 6px;
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

.toolboxBtnOpen {
  position: fixed;
  margin-top: 6px;
  left: 251px; 
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

.handle {
  cursor: move;
}

.card {
    background-color: var(--toolbar-bg);
    border: 1px solid var(--toolbar-border);
}

.card-header {
    padding: 0px;
    border-bottom: 0px;
    background-color: var(--toolbar-bg);
}

.card-header .btn {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
}

.card-header .btn:focus {
    box-shadow: 0 0 0 0;
}

.card-body {
    padding: 5px 12px;
}

.border-bottom {
  border-bottom: 1px solid var(--toolbar-hr) !important;
}

.toolbarViewFloat {
  position: fixed; 
  z-index: 2; 
  top: 41px;
  width: 255px; 
  opacity: 0.8; 
}
</style>